@import "_variables.scss";
.co-home__hero {
  min-height: 300px;
  background-color: #E0E0E0;
  display: flex;
  padding: 40px;
  align-items: center;
}

.co-home__features {
  min-height: 340px;
}