@import "_variables.scss";
.co-embed {
  text-align: center;
  padding: 2em;
  width: 600px;
  margin: 4em auto;
  position: relative;
}

.co-embed__code {
  background-color: #f4f4f4;
  text-align: left;
  padding: 2em;
  width: 600px;
  height: 100px;
}

.mdc-select {
  width: 100%;
  position: relative;
}
.mdc-select__selected-text {
  text-align: left;
}