@import "_variables.scss";
.import-export-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid grey;
  margin: 2rem;
  padding: 1rem;
}
.import {
  &--desc {
    border-top: 1px solid grey;
    p {
      text-align: left;
    }
  }

  &--results {
    margin: 0 2rem;
    text-align: left;
  }
}
